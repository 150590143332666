import React from 'react';
import FontAvenir300 from './FontAvenir300';
import FontAvenir400 from './FontAvenir400';
import FontAvenir500 from './FontAvenir500';
import FontAvenir700 from './FontAvenir700';
import FontAvenir900 from './FontAvenir900';

// https://en.bestfonts.pro/font/avenir

const FontAvenir = () => (
  <>
    <FontAvenir300 key="300" />
    <FontAvenir400 key="400" />
    <FontAvenir500 key="500" />
    <FontAvenir700 key="700" />
    <FontAvenir900 key="900" />
  </>
);
export default FontAvenir;
