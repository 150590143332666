import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import GCButtonWhite from './GCButtonWhite';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      zIndex: 1299,
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  });

const HomeButton: React.FunctionComponent<Props> = ({ classes }) => (
  <GCButtonWhite
    className={classes.root}
    component="a"
    href="https://gamecompanies.com/"
  >
    <HomeOutlined className={classes.leftIcon} />
    Home
  </GCButtonWhite>
);
type Props = {} & WithStyles<typeof styles>;

export default withStyles(styles)(HomeButton);
