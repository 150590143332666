import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      listStyleType: 'none',
      padding: 0,
      marginLeft: 0,
      marginBottom: 8,
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        marginRight: 15,
      },
    },
  });

const FooterList = ({ classes, children }: Props) => <ul className={classes.root}>{children}</ul>;
type Props = JSX.ElementChildrenAttribute & WithStyles<typeof styles>;
export default withStyles(styles)(FooterList);
