import classnames from 'clsx';
import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = createStyles({
  root: {
    backgroundColor: 'white',
    color: '#6B45DB',
    borderRadius: 50,
    padding: '12px 22px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
});

type Props = { target?: string; rel?: string; component: any; to?: string } & ButtonProps & WithStyles<typeof styles>;

const GCButtonWhite: React.FunctionComponent<Props> = ({ classes, className, children, ...props }) => (
  <Button classes={{ root: classnames(classes.root, className) }} variant="contained" {...props}>
    {children}
  </Button>
);
export default withStyles(styles)(GCButtonWhite);
