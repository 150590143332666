import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Roboto"',
      fontStyle: 'normal',
      fontWeight: 400,
      src: 'url("/fonts/roboto-v18-latin-regular.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Roboto"), local("Roboto-Regular"),
url("/fonts/roboto-v18-latin-regular.eot?#iefix") format("embedded-opentype"),
url("/fonts/roboto-v18-latin-regular.woff2") format("woff2"),
url("/fonts/roboto-v18-latin-regular.woff") format("woff"),
url("/fonts/roboto-v18-latin-regular.ttf") format("truetype"),
url("/fonts/roboto-v18-latin-regular.svg#Roboto") format("svg")`,
    },
  },
});

const FontRoboto400 = () => null;
export default withStyles(styles)(FontRoboto400);
