import React from 'react';
import FontRoboto100 from './FontRoboto100';
import FontRoboto300 from './FontRoboto300';
import FontRoboto400 from './FontRoboto400';
import FontRoboto500 from './FontRoboto500';
import FontRoboto700 from './FontRoboto700';
import FontRoboto900 from './FontRoboto900';

const FontRoboto = () => (
  <>
    <FontRoboto100 key="100" />
    <FontRoboto300 key="300" />
    <FontRoboto400 key="400" />
    <FontRoboto500 key="500" />
    <FontRoboto700 key="700" />
    <FontRoboto900 key="900" />
  </>
);
export default FontRoboto;
