import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Roboto"',
      fontStyle: 'normal',
      fontWeight: 100,
      src: 'url("/fonts/roboto-v18-latin-100.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Roboto Thin"), local("Roboto-Thin"),
url("/fonts/roboto-v18-latin-100.eot?#iefix") format("embedded-opentype"),
url("/fonts/roboto-v18-latin-100.woff2") format("woff2"),
url("/fonts/roboto-v18-latin-100.woff") format("woff"),
url("/fonts/roboto-v18-latin-100.ttf") format("truetype"),
url("/fonts/roboto-v18-latin-100.svg#Roboto") format("svg")`,
    },
  },
});
const FontRoboto100 = () => null;
export default withStyles(styles)(FontRoboto100);
