import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Roboto"',
      fontStyle: 'normal',
      fontWeight: 300,
      src: 'url("/fonts/roboto-v18-latin-300.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Roboto Light"), local("Roboto-Light"),
url("/fonts/roboto-v18-latin-300.eot?#iefix") format("embedded-opentype"),
url("/fonts/roboto-v18-latin-300.woff2") format("woff2"),
url("/fonts/roboto-v18-latin-300.woff") format("woff"),
url("/fonts/roboto-v18-latin-300.ttf") format("truetype"),
url("/fonts/roboto-v18-latin-300.svg#Roboto") format("svg")`,
    },
  },
});

const FontRoboto300 = () => null;
export default withStyles(styles)(FontRoboto300);
