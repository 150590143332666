import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    textColor: {
      color: theme.palette.common.white,
    },
  });

const FooterTitle: React.SFC<Props> = ({ classes, children }) => (
  <Typography
    variant="h6"
    component="h3"
    classes={{ root: classes.textColor }}
    gutterBottom
  >
    {children}
  </Typography>
);
type Props = {} & WithStyles<typeof styles>;
export default withStyles(styles)(FooterTitle);
