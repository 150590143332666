import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import absoluteUrl from '../../helpers/absoluteUrl';
import Page from '../../layout/Page';
import HelmetComponent from '../components/HelmetComponent';
import TitleCell from '../components/TitleCell';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      userSelect: 'none',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(5),
      minHeight: 1100,
      [theme.breakpoints.up('sm')]: {
        minHeight: 1100,
      },
    },
    inner: {
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: theme.spacing(5),
      margin: 'auto',
      marginTop: 80,
      width: 'auto',
      maxWidth: 460,
      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 240,
      },
    },
    link: {
      margin: 'auto',
      marginTop: theme.spacing(2),
    },
    img: {
      height: 64,
      [theme.breakpoints.up('sm')]: {
        height: 'unset',
        width: 180,
      },
      [theme.breakpoints.up('md')]: {
        width: 220,
      },
    },
  });

const title = 'GC Insider';
const description = 'The home of all game companies & esports professionals';

const About: React.FunctionComponent<Props> = props => {
  const { classes } = props;
  return (
    <Page className={classes.root}>
      <HelmetComponent
        title={title}
        description={description}
        canonical={absoluteUrl('')}
        url={absoluteUrl('')}
        facebookImage="https://images.gamecompanies.com/seo/gcinsider-og-home-2.jpg"
        twitterImage="https://images.gamecompanies.com/seo/gcinsider-twitter-home-2.jpg"
        twitterImageAlt="Screenshot of GC Insider start page."
      />
      <Paper className={classes.inner}>
        <TitleCell
          title="We are in Early Access!"
          subtitle="We are currently developing the web version of GC Insider. In the meanwhile, you can already start using GC Insider on your mobile."
        />
        <div className={classes.link}></div>
        <a
          className={classes.link}
          href="https://play.google.com/apps/testing/com.gamecompanies.insider"
        >
          <img
            className={classes.img}
            src="/GooglePlay.png"
            alt="Get it on Google Play image"
            title="Get it on Google Play image"
            loading="lazy"
          />
        </a>
        <a
          className={classes.link}
          href="https://testflight.apple.com/join/EyioMtIF"
        >
          <img
            className={classes.img}
            src="/AppStore.png"
            alt="Download on App Store image"
            title="Download on App Store image"
            loading="lazy"
          />
        </a>
      </Paper>
    </Page>
  );
};
type Props = WithStyles<typeof styles>;
export default withStyles(styles)(About);
