import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Roboto"',
      fontStyle: 'normal',
      fontWeight: 700,
      src: 'url("/fonts/roboto-v18-latin-700.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Roboto Bold"), local("Roboto-Bold"),
url("/fonts/roboto-v18-latin-700.eot?#iefix") format("embedded-opentype"),
url("/fonts/roboto-v18-latin-700.woff2") format("woff2"),
url("/fonts/roboto-v18-latin-700.woff") format("woff"),
url("/fonts/roboto-v18-latin-700.ttf") format("truetype"),
url("/fonts/roboto-v18-latin-700.svg#Roboto") format("svg")`,
    },
  },
});

const FontRoboto700 = () => null;
export default withStyles(styles)(FontRoboto700);
