import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Avenir"',
      fontStyle: 'normal',
      fontWeight: 500,
      src: 'url("/fonts/Avenir-Medium.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Avenir Medium"), local("Avenir-Medium"),
url("/fonts/Avenir-Medium.woff2") format("woff2"),
url("/fonts/Avenir-Medium.woff") format("woff"),
url("/fonts/Avenir-Medium.ttf") format("truetype")`,
    },
  },
});

const FontAvenir500 = () => null;
export default withStyles(styles)(FontAvenir500);
