import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Material Icons"',
      fontStyle: 'normal',
      fontWeight: 400,
      src: 'url("/fonts/MaterialIcons-Regular.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Material Icons"),
local("MaterialIcons-Regular"),
url("/fonts/MaterialIcons-Regular.woff2") format("woff2"),
url("/fonts/MaterialIcons-Regular.woff") format("woff"),
url("/fonts/MaterialIcons-Regular.ttf") format("truetype")`,
    },
    '.material-icons': {
      fontFamily: '"Material Icons"',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 24,
      display: 'inline-block',
      lineHeight: 1,
      textTransform: 'none',
      letterSpacing: 'normal',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      direction: 'ltr',
      WebkitFontSmoothing: 'antialiased',
      textRendering: 'optimizeLegibility',
      MozOsxFontSmoothing: 'grayscale',
      fontFeatureSettings: '"liga"',
    },
  },
});

const MaterialIcons = () => null;
export default withStyles(styles)(MaterialIcons);
