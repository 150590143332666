import React from 'react';
import { grey } from '@material-ui/core/colors';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = ({ breakpoints, palette, spacing, typography }: Theme) =>
  createStyles({
    root: {
      maxWidth: 1160,
      margin: '0 auto',
      padding: 0,
      color: palette.common.black,
      textAlign: 'center',
      [breakpoints.up('md')]: {
        padding: 0,
      },
    },
    divider: {
      width: 65,
      height: 2,
      backgroundColor: '#6043C7',
      margin: 'auto',
      marginTop: spacing(1.5),
      marginBottom: spacing(1),
    },
    subtitle1: {
      color: grey[800],
      fontSize: typography.pxToRem(18),
      fontWeight: 300,
      lineHeight: 1.5,
    },
  });

const TitleCell = ({
  classes,
  title,
  subtitle,
  hideDivider = false,
  ...other
}: Props) => (
  <div className={`title-cell ${classes.root}`} {...other}>
    <Typography variant="h5" component="h1">
      {title}
    </Typography>
    <div className={classes.divider} />
    {subtitle && (
      <Typography
        variant="body1"
        component="h2"
        classes={{ body1: classes.subtitle1 }}
      >
        {subtitle}
      </Typography>
    )}
  </div>
);
type ComponentProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  hideDivider?: boolean;
};
type Props = ComponentProps & WithStyles<typeof styles>;
TitleCell.defaultProps = {
  hideDivider: false,
};
export default withStyles(styles)(TitleCell);
