import classnames from 'clsx';
// import { Meteor } from 'meteor/meteor';
import React from 'react';
// import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import FooterComponent from './components/FooterComponent';
import FooterList from './components/FooterList';
import FooterListItem from './components/FooterListItem';
import FooterTitle from './components/FooterTitle';
import { mediaQuery } from '../../GlobalStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 150,
      backgroundImage:
        'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-bottom.png)',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // [mediaQuery]: {
      //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-bottom@2x.png)',
      // },
      background:
        'linear-gradient(155deg, #0C052F 0%, #130940 12%, #1F105F 88%, #25136D 100%)',
      [theme.breakpoints.up('sm')]: {
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-bottom.png)',
        // [mediaQuery]: {
        //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-bottom@2x.png)',
        // },
        // backgroundSize: '100% cover',
      },
      [theme.breakpoints.up('md')]: {
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-bottom.png)',
        // [mediaQuery]: {
        //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-bottom@2x.png)',
        // },
      },
      [theme.breakpoints.up('lg')]: {
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-bottom.png)',
        // [mediaQuery]: {
        //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-bottom@2x.png)',
        // },
      },
      [theme.breakpoints.up('xl')]: {
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xxl-bottom.png)',
        // [mediaQuery]: {
        //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xxl-bottom@2x.png)',
        // },
      },
    },
    textColor: {
      color: 'white',
    },
    notHr: {
      margin: '0 0',
      padding: '10px 20px',
      flex: '1 1 auto',
      borderTop: `1 solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
        borderTopWidth: 0,
      },
      [theme.breakpoints.up('md')]: {
        padding: '10px 0',
      },
    },
    nav: {
      minHeight: 60,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      borderTop: 0,
    },
    icon: {
      verticalAlign: 'sub',
      display: 'inline-flex',
      marginRight: theme.spacing(1),
    },
    bottom: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    bottomLeft: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      [theme.breakpoints.up('md')]: {
        // maxWidth: '80%',
        flexBasis: '80%',
      },
    },
    bottomRight: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      display: 'flex',
      marginBottom: 10,
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        // maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    bottomRightInner: {
      margin: 'auto 0',
    },
    flexContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    section: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    logo: {
      marginRight: theme.spacing(2),
    },
  });

const Footer = ({ classes }: Props) => (
  <div className={classes.root}>
    <FooterComponent>
      <nav className={classnames(classes.nav, classes.notHr)}>
        <div className={classes.flexContainer}>
          <div className={classes.section}>
            <FooterTitle>
              <a href="https://gamecompanies.com/">GC</a>
            </FooterTitle>
            <FooterList>
              <FooterListItem>
                <a href="https://gamecompanies.com/">Home</a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/map">Map</a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://gcinsider.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Insider
                </a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/industries">Industries</a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSf-5JZX_CAF3doJLQlUYTDQpzyJKFh9zxNhlDxpwxZoSNLrnw/viewform">
                  Partner
                </a>
              </FooterListItem>
            </FooterList>
          </div>
          <div className={classes.section}>
            <FooterList>
              <FooterTitle>Early Access</FooterTitle>
              <FooterListItem>
                <a
                  href="https://play.google.com/apps/testing/com.gamecompanies.insider"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GC Insider for Android
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://testflight.apple.com/join/EyioMtIF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GC Insider for iOS
                </a>
              </FooterListItem>
            </FooterList>
          </div>
          <div className={classes.section}>
            <FooterTitle>Social</FooterTitle>
            <FooterList>
              <FooterListItem>
                <a
                  href="https://twitter.com/gamecompanies_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-twitter-white.svg"
                    alt="Twitter icon"
                    title="Twitter icon"
                    loading="lazy"
                  />
                  Twitter
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://www.facebook.com/gamecompanies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-facebook-white.svg"
                    alt="Facebook icon"
                    title="Facebook icon"
                    loading="lazy"
                  />
                  Facebook
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://www.instagram.com/gamecompanies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-instagram-white.svg"
                    alt="Instagram icon"
                    title="Instagram icon"
                    loading="lazy"
                  />
                  Instagram
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://www.linkedin.com/company/gamecompanies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-linkedin-white.svg"
                    alt="LinkedIn icon"
                    title="LinkedIn icon"
                    loading="lazy"
                  />
                  LinkedIn
                </a>
              </FooterListItem>
              {/* <FooterListItem>
                <a
                  href="https://medium.com/@gamecompanies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-medium-white.svg"
                    alt="Medium icon"
                    title="Medium icon"
                    loading="lazy"
                  />
                  Medium
                </a>
              </FooterListItem> */}
              <FooterListItem>
                <a
                  href="https://www.reddit.com/user/gamecompanies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-reddit-white.svg"
                    alt="Reddit icon"
                    title="Reddit icon"
                    loading="lazy"
                  />
                  Reddit
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://discord.com/invite/BhFnTpY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.icon}
                    src="https://images.gamecompanies.com/icons/social-discord-white.svg"
                    alt="Discord icon"
                    title="Discord icon"
                    loading="lazy"
                  />
                  Discord
                </a>
              </FooterListItem>
            </FooterList>
          </div>
          <div className={classes.section}>
            <FooterTitle>Other</FooterTitle>
            <FooterList>
              <FooterListItem>
                <a href="https://gamecompanies.com/presskit">Press kit</a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/sitemap">Sitemap</a>
              </FooterListItem>
            </FooterList>
          </div>
          <div className={classes.section}>
            <FooterTitle>Policies</FooterTitle>
            <FooterList>
              <FooterListItem>
                <a href="https://gamecompanies.com/terms-of-service">
                  Terms of service
                </a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/privacy-policy">
                  Privacy policy
                </a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/copyright-policy">
                  Copyright policy
                </a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/image-policy">
                  Image policy
                </a>
              </FooterListItem>
              <FooterListItem>
                <a href="https://gamecompanies.com/cookie-policy">
                  Cookie policy
                </a>
              </FooterListItem>
            </FooterList>
          </div>
        </div>
      </nav>
      <div className={classnames(classes.bottom, classes.notHr)}>
        <div className={classnames(classes.flexContainer, classes.bottomLeft)}>
          <ListItem disableGutters>
            <img
              className={classes.logo}
              src="/images/icon_48_appbar.svg"
              height={48}
              width={48}
              alt="Game Companies logo"
              title="Game Companies logo"
              loading="lazy"
            />
            <ListItemText
              classes={{
                primary: classes.textColor,
                secondary: classes.textColor,
              }}
              primary="GameCompanies.com"
              secondary="The home of all game companies & esports professionals."
            />
          </ListItem>
        </div>
        <span className={classes.bottomRight}>
          <span style={{ fontSize: 20 }}>{String.fromCharCode(169)}</span>
          <span className={classes.bottomRightInner}>
            &nbsp;2023&nbsp;GameCompanies.com
          </span>
        </span>
      </div>
    </FooterComponent>
  </div>
);
type Props = {} & WithStyles<typeof styles>;
export default withStyles(styles)(Footer);
