import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Avenir"',
      fontStyle: 'normal',
      fontWeight: 300,
      src: 'url("/fonts/Avenir-Light.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Avenir Light"), local("Avenir-Light"),
url("/fonts/Avenir-Light.woff2") format("woff2"),
url("/fonts/Avenir-Light.woff") format("woff"),
url("/fonts/Avenir-Light.ttf") format("truetype")`,
    },
  },
});

const FontRoboto300 = () => null;
export default withStyles(styles)(FontRoboto300);
