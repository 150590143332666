import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string;
  description?: string;
  canonical?: string;
  url?: string;
  facebookImage?: string;
  twitterImage?: string;
  twitterImageAlt?: string;
};

const HelmetComponent: React.FunctionComponent<Props> = ({ title, description, canonical, url, facebookImage, twitterImage, twitterImageAlt, children }) => {
  return (
    <Helmet>
      {title && <title>{`${title} | GameCompanies.com`}</title>}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}

      {url && <meta property="og:url" content={url} />}
      {title && <meta property="og:title" content={`${title} | GameCompanies.com`} />}
      {description && <meta property="og:description" content={description} />}
      {facebookImage && <meta property="og:image" content={facebookImage} />}

      {title && <meta name="twitter:title" content={`${title} | GameCompanies.com`} />}
      {description && <meta name="twitter:description" content={description} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      {twitterImageAlt && <meta name="twitter:image:alt" content={twitterImageAlt} />}
      {children}
    </Helmet>
  );
};
export default HelmetComponent;
