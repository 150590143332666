import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Avenir"',
      fontStyle: 'normal',
      fontWeight: 400,
      src: 'url("/fonts/Avenir-Roman.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Avenir"), local("Avenir-Roman"),
url("/fonts/Avenir-Roman.woff2") format("woff2"),
url("/fonts/Avenir-Roman.woff") format("woff"),
url("/fonts/Avenir-Roman.ttf") format("truetype")`,
    },
  },
});

const FontAvenir400 = () => null;
export default withStyles(styles)(FontAvenir400);
