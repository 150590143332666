import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Avenir"',
      fontStyle: 'normal',
      fontWeight: 900,
      src: 'url("/fonts/Avenir-Black.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Avenir Black"), local("Avenir-Black"),
url("/fonts/Avenir-Black.woff2") format("woff2"),
url("/fonts/Avenir-Black.woff") format("woff"),
url("/fonts/Avenir-Black.ttf") format("truetype")`,
    },
  },
});

const FontAvenir900 = () => null;
export default withStyles(styles)(FontAvenir900);
