import classnames from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet';
// import {
//   Link,
//   Redirect,
//   Route,
//   RouteComponentProps,
//   Switch,
// } from 'react-router-dom';
import { WithWidth, withWidth } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MapOutlined from '@material-ui/icons/MapOutlined';
import NotFound from '../NotFound';
import AboutPage from './about/About';
import GCButton from './components/GCButtonWhite';
import HomeButton from './components/HomeButton';
import Footer from './footer/Footer';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    flex: {
      flex: 1,
    },
    menuIcon: {
      marginLeft: -12,
      marginRight: theme.spacing(2),
    },
    textColor: { color: 'white' },
    link: {
      textDecoration: 'none',
      '&:visited': {
        textDecoration: 'none',
      },
    },
    toolbar: {
      minHeight: 74,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  });

const App: React.FunctionComponent<Props> = ({ classes, width }) => (
  <>
    <Helmet>
      <html lang="en" />
      <title>GC Insider</title>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="GC Insider" />
      <meta property="og:locale" content="en_US" />
      <meta
        property="og:image"
        content="https://appimages.gamecompanies.com/1200x630/seo/gamecompanies-facebook-2.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta
        name="twitter:site"
        content={`@${Meteor.settings.public.social.twitter}`}
      />
      <meta
        name="twitter:creator"
        content={`@${Meteor.settings.public.social.twitter}`}
      /> */}
      <meta
        name="twitter:image"
        content="https://appimages.gamecompanies.com/1200x600/seo/gamecompanies-twitter-2.png"
      />
      <meta name="twitter:image:alt" content="Game companies picture" />
    </Helmet>
    <div>
      <Toolbar className={classes.toolbar}>
        <img
          className={classes.menuIcon}
          src="/images/icon_48_appbar.svg"
          height={48}
          width={48}
          alt="Game Companies logo"
          title="Game Companies logo"
          loading="lazy"
        />
        <div className={classnames(classes.link, classes.flex)}>
          <Typography
            variant="h6"
            component="div"
            classes={{ root: classes.textColor }}
            color="textPrimary"
          >
            GC Insider
          </Typography>
        </div>
        <HomeButton />
      </Toolbar>
    </div>
    <AboutPage />
    <Footer />
  </>
);

type Props = WithStyles<typeof styles> & WithWidth;

export default withStyles(styles)(withWidth()(App));
