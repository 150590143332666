import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 10,
      // paddingBottom: theme.spacing(4),
      color: theme.palette.common.white,
      maxWidth: 1380,
      margin: 'auto',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
        // paddingBottom: theme.spacing(4),
        padding: '0 30px',
      },
      '& a': {
        color: theme.palette.common.white,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  });

const FooterComponent = ({ classes, children }: Props) => (
  <footer className={classes.root}>{children}</footer>
);
type Props = JSX.ElementChildrenAttribute & WithStyles<typeof styles>;
export default withStyles(styles)(FooterComponent);
