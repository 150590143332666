import classnames from 'clsx';
import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    textColor: {
      color: theme.palette.common.white,
    },
    body2: {
      fontWeight: 300,
    },
    marginRight: {
      marginRight: 18,
    },
  });

const FooterListItem: React.FunctionComponent<Props> = ({ classes, children, noMarginRight = false }) => (
  <Typography
    component="li"
    variant="body2"
    classes={{
      root: classes.textColor,
      body2: classnames(classes.body2, {
        [classes.marginRight]: !noMarginRight,
      }),
    }}
    gutterBottom
  >
    {children}
  </Typography>
);
type ComponentProps = {
  noMarginRight?: boolean;
};
type Props = JSX.ElementChildrenAttribute & ComponentProps & WithStyles<typeof styles>;
export default withStyles(styles)(FooterListItem);
