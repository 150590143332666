import classnames from 'clsx';
import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Footer from '../app/footer/Footer';

const styles = createStyles({
  root: {
    display: 'flex !important',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1380,
    margin: 'auto',
  },
  large: {
    maxWidth: 1540,
  },
});

const Page: React.FunctionComponent<Props> = ({
  classes,
  className,
  children,
  large = false,
}) => (
  <div
    className={classnames(classes.root, className, { [classes.large]: large })}
  >
    {children}
  </div>
);
type Props = {
  className?: string;
  large?: boolean;
} & WithStyles<typeof styles>;

export default withStyles(styles)(Page);
