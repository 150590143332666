import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './app/AppNew';
import FontAvenir from './fonts/FontAvenir';
import FontRoboto from './fonts/FontRoboto';
import GlobalStyles from './GlobalStyles';
import MaterialIcons from './MaterialIcons';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

class AppWrapper extends React.Component<Props> {
  render() {
    return (
      <div className="app-wrapper">
        <div className="app-wrapper2">
          <div className="app-wrapper3">
            <CssBaseline />
            <FontRoboto />
            <FontAvenir />
            <MaterialIcons />
            <GlobalStyles />
            <App />
          </div>
        </div>
      </div>
    );
  }
}

type Props = {};

export default AppWrapper;
