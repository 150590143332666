import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Avenir"',
      fontStyle: 'normal',
      fontWeight: 700,
      src: 'url("/fonts/Avenir-Heavy.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Avenir Heavy"), local("Avenir-Heavy"),
url("/fonts/Avenir-Heavy.woff2") format("woff2"),
url("/fonts/Avenir-Heavy.woff") format("woff"),
url("/fonts/Avenir-Heavy.ttf") format("truetype")`,
    },
  },
});

const FontAvenir700 = () => null;
export default withStyles(styles)(FontAvenir700);
