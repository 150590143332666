import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  '@global': {
    '@font-face': {
      fontFamily: '"Roboto"',
      fontStyle: 'normal',
      fontWeight: 900,
      src: 'url("/fonts/roboto-v18-latin-900.eot")',
      // @ts-ignore
      // eslint-disable-next-line
      src: `local("Roboto Black"), local("Roboto-Black"),
url("/fonts/roboto-v18-latin-900.eot?#iefix") format("embedded-opentype"),
url("/fonts/roboto-v18-latin-900.woff2") format("woff2"),
url("/fonts/roboto-v18-latin-900.woff") format("woff"),
url("/fonts/roboto-v18-latin-900.ttf") format("truetype"),
url("/fonts/roboto-v18-latin-900.svg#Roboto") format("svg")`,
    },
  },
});

const FontRoboto900 = () => null;
export default withStyles(styles)(FontRoboto900);
