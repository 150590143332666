const absoluteUrl = (path: string) => {
  // var location = typeof window === 'object' && window.location;
  let url =
    process.env.NODE_ENV === 'production'
      ? 'https://gcinsider.com/'
      : 'http://localhost:8000/';
  // if (location) {
  // 	console.log('CLIENT');
  // } else {
  // 	console.log('SERVER');
  // }
  if (path) {
    // join url and path with a / separator
    while (path.startsWith('/')) {
      path = path.slice(1);
    }
    url += path;
  }
  return url;
};

export default absoluteUrl;
