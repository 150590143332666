import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

export const mediaQuery =
  '@media only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),only screen and (    min--moz-device-pixel-ratio: 1.3 ),only screen and (      -o-min-device-pixel-ratio: 2.6/2 ),only screen and (         min-device-pixel-ratio: 1.3 ),only screen and ( min-resolution: 124.8dpi ),only screen and ( min-resolution: 1.3dppx )';
const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        fontFamily: 'Roboto, Arial, sans-serif',
        backgroundColor: '#FAFAFA',
      },
      '.app-wrapper': {
        minHeight: '100vh',
        display: ' flex',
        flexDirection: 'column',
        // backgroundImage:
        //   'url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-top.png), url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-bottom.png)',
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-middle.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        [mediaQuery]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-middle@2x.png)',
        },
        [theme.breakpoints.up('sm')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-middle-left.png)',
          backgroundPosition: 'center left',
          backgroundSize: 'auto 600px',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-middle-left@2x.png)',
          },
          // backgroundSize: '80% cover',
        },
        [theme.breakpoints.up('md')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-middle-left.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-middle-left@2x.png)',
          },
        },
        [theme.breakpoints.up('lg')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-left.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-left@2x.png)',
          },
          // backgroundSize: 'contain, 50%',
        },
        [theme.breakpoints.up('xl')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-left.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-left@2x.png)',
          },
        },
      },
      '.app-wrapper2': {
        minHeight: '100vh',
        display: ' flex',
        flexDirection: 'column',
        // backgroundImage:
        //   'url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-top.png), url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-bottom.png)',
        // backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-middle.png)',
        // backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // backgroundSize: 'auto 600px',
        // background:
        //   'linear-gradient(155deg, #0C052F 0%, #130940 12%, #1F105F 88%, #25136D 100%)',
        [theme.breakpoints.up('sm')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-middle-right.png)',
          backgroundPosition: 'center right',
          backgroundSize: 'auto 600px',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-middle-right@2x.png)',
          },
        },
        [theme.breakpoints.up('md')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-middle-right.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-middle-right@2x.png)',
          },
        },
        [theme.breakpoints.up('lg')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-right.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-right@2x.png)',
          },
          // backgroundSize: 'contain, 50%',
        },
        [theme.breakpoints.up('xl')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-right.png)',
          [mediaQuery]: {
            backgroundImage:
              'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-middle-right@2x.png)',
          },
        },
      },
      '.app-wrapper3': {
        minHeight: '100vh',
        display: ' flex',
        flexDirection: 'column',
        // backgroundImage:
        //   'url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-top.png), url(https://images.gamecompanies.com/backgrounds/gcinsider/background11-bottom.png)',
        backgroundImage:
          'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-top.png)',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        // [mediaQuery]: {
        //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xs-top@2x.png)',
        //   backgroundSize: '50% cover',
        // },
        [theme.breakpoints.up('sm')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-top.png)',
          backgroundPosition: 'top center',
          // [mediaQuery]: {
          //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-sm-top@2x.png)',
          // },
        },
        [theme.breakpoints.up('md')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-top.png)',
          // [mediaQuery]: {
          //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-md-top@2x.png)',
          // },
        },
        [theme.breakpoints.up('lg')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-top.png)',
          // [mediaQuery]: {
          //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-lg-top@2x.png)',
          // },
          // backgroundSize: 'contain, 50%',
        },
        [theme.breakpoints.up('xl')]: {
          backgroundImage:
            'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xxl-top.png)',
          // [mediaQuery]: {
          //   backgroundImage: 'url(https://images.gamecompanies.com/backgrounds/gcinsider/background1-xxl-top@2x.png)',
          // },
        },
      },
    },
  });

const GlobalStyles: React.SFC<Props> = () => null;
type Props = WithStyles<typeof styles>;
export default withStyles(styles)(GlobalStyles);
